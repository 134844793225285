import { useEffect, useState } from "react";
import UpsBoxes from "../../atoms/UpsBoxes/UpsBoxes";
import { UserInputs } from "../../organisms/UserInputs/UserInputs";
import { FormattedUserInputsInterface, PackagingType, PackedBox, UserInputsInterface } from "../../organisms/interfaces";

import './Main.css';
import { getBoxes } from "../../atoms/UpsApi/UpsApi";
import { FaSpinner } from "react-icons/fa";

export default function Main() {
    const [userInputs, _setUserInputs] = useState<FormattedUserInputsInterface>({
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        postalCode: "",
        serviceType: "", // intentionally not defaulting to Ground or otherwise
        packType: PackagingType.Basic
    });

    const [apiRunning, setApiRunning] = useState<boolean>(false)
    const [isUserInputComplete, setIsUserInputComplete] = useState<boolean>(false)
    const [boxes, setBoxes] = useState<PackedBox[]>([]);
    const [noValidBoxes, setNoValidBoxes] = useState<boolean>(false)

    const [dimensionalWeight, setDimensionalWeight] = useState<number>(0)

    useEffect(() => {
        determineUserInputStatus()
        calcDimensionalWeight()
    }, [userInputs]);



    const handleCalculateShippingCostClick = async () => {
        if (isUserInputComplete) {
            setApiRunning(true)
            setNoValidBoxes(false)
            try {
                var response = await getBoxes(userInputs, dimensionalWeight)
                setApiRunning(false)
                setBoxes(response)

                if(response == null || response.length == 0) {
                    setNoValidBoxes(true)
                }
            } catch (e) {
                setApiRunning(false)
            }
        }
    }

    function setUserInputs(userInputs: UserInputsInterface) {
        _setUserInputs({
            length: userInputs.length.length > 0 ? parseInt(userInputs.length) : 0,
            width: userInputs.width.length > 0 ? parseInt(userInputs.width) : 0,
            height: userInputs.height.length > 0 ? parseInt(userInputs.height) : 0,
            weight: userInputs.weight.length > 0 ? parseInt(userInputs.weight) : 0,
            postalCode: userInputs.postalCode,
            serviceType: userInputs.serviceType,
            packType: userInputs.packType
        })
    }

    function calcDimensionalWeight() {
        if (userInputs.length !== 0
            && userInputs.width !== 0
            && userInputs.height !== 0)
            // +1 to each dimension to meet "real world" dimeinsion calculation
            setDimensionalWeight(
                Math.ceil(((userInputs.length + 1)
                    * (userInputs.width + 1)
                    * (userInputs.height + 1)) / 166));
        else
            setDimensionalWeight(0);
    }

    function determineUserInputStatus() {
        if (userInputs.length !== undefined
            && userInputs.height !== undefined
            && userInputs.postalCode
            && userInputs.postalCode.length > 4
            && userInputs.serviceType !== "") {
            // weight is optional
            setIsUserInputComplete(true)
        } else {
            setIsUserInputComplete(false)
        }
    }

    return (
        <div className="main">
            <UserInputs setUserInputs={setUserInputs} dimensionalWeight={dimensionalWeight} />

            <div>
                <button className="btn btn-primary m-3"
                    disabled={!isUserInputComplete || apiRunning}
                    onClick={handleCalculateShippingCostClick}>Calculate Shipping Cost</button>
                {apiRunning ? (<FaSpinner className="fa-spin" />) : <></>}
            </div>

            {boxes && boxes.length > 0 && <UpsBoxes boxes={boxes} />}
            {noValidBoxes && <div>No valid boxes returned.</div>}
        </div>
    );
}