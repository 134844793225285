import WebRoutes from "../../atoms/WebRoutes/WebRoutes";
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import TopNavbar from "../TopNavBar/TopNavBar";

function App() {
  return (
    <div>
      <Authenticator>
        {({ signOut, user }) => (
          <main>
            <TopNavbar signOut={signOut} user={user}/>            
            <WebRoutes />            
          </main>
        )}
      </Authenticator>
    </div>
  )
}

export default App;