import { Routes, Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Main from "../../screens/Main/Main";
import WebsiteAuth from "../../atoms/WebsiteAuth/WebsiteAuth";

export default function WebRoutes() {
  /* <Routes> looks through its children <Route>s and
            renders the first one that matches the current URL. */
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <WebsiteAuth>
              <Main />
            </WebsiteAuth>
          }
        />
      </Routes>
    </Router>
  );
}