import { useEffect, useState } from "react";
import { Amplify, Auth } from "aws-amplify";
import { config } from "./config";

// one time configuration for authentication
if (!Amplify.Auth.userPool) {
  Amplify.configure({
    region: config.cognito.region,
    authenticationFlowType: "USER_PASSWORD_AUTH",
    userPoolId: config.cognito.userPoolId,
    userPoolWebClientId: config.cognito.appClientId,
    oauth: {
      domain: config.cognito.domainUrl,
      scope: config.cognito.scopes,
      redirectSignIn: config.cognito.redirectSignInUrl,
      redirectSignOut: config.cognito.redirectSignOutUrl,
      responseType: "code", // REFRESH token generated with 'code'
    }
  });
}

/* Security page is locked down to a very specific set of allowed users with backend logic */
export default function WebsiteAuth(props) {
  const [cognitoUser, setCognitoUser] = useState();

  // Only using this approach to trigger a re-render
  // for when Amplify initializes
  useEffect(() => {
    Auth.currentAuthenticatedUser().then(
      (cognitoUser) => {
        if (!cognitoUser) {
          console.log("login")
          Auth.federatedSignIn({}); //redirects to Cognito sign in
        } else {
          setCognitoUser(cognitoUser);
        }
      },
      (error) => {
        console.log("Error")
        console.log(error);
        Auth.federatedSignIn({}); //redirects to Cognito sign in
      }
    );
  }, []);

  return <>{!cognitoUser ? <div className="main--websiteauth"> <></></div> : props.children}</>;
}

export async function getCustomAttributes() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user.attributes;
  } catch (error) {
    console.error('Error fetching custom attributes:', error);
  }
}