import { useEffect, useState } from 'react';
import './UserInputs.css';
import { PackagingType, UserInputsInterface } from '../interfaces';

interface IProps {
  setUserInputs: Function,
  dimensionalWeight: number
}

const SERVICE_TYPES = [
  { key: "01", description: "Next Day Air" },
  { key: "02", description: "2nd Day Air" },
  { key: "03", description: "Ground" },
  { key: "12", description: "3 Day Select" },
];

const PACK_TYPES = ["Basic", "Standard", "Fragile", "None"];


export function UserInputs(props: IProps) {
  const [formData, setFormData] = useState<UserInputsInterface>({
    length: "",
    width: "",
    height: "",
    weight: "",
    postalCode: "",
    serviceType: "",
    packType: PackagingType.Basic
  });

  useEffect(() => {
    props.setUserInputs(formData);
  }, [formData])

  const handlePostalCodeChange = (e: { target: { name: any; value: string; }; }) => {
    const { name, value } = e.target;
    if (/^[0-9]*$/.test(value)) {
      var input = value.length > 5 ? value.slice(0,5) : value
      setFormData({ ...formData, [name]: input });
    }
  }

  const handleDimensionChange = (e: { target: { name: any; value: string; }; }) => {
    const { name, value } = e.target;
    
    if (/^[0-9]*$/.test(value)) { // must be number value
      //must be within a range
      var input = parseInt(value)

      input = Math.min(input, 999) // generic max

      setFormData({ ...formData, [name]: input.toString() });
    }
  }

  const handleServiceTypeChange = (e: any) => setFormData({ ...formData, 'serviceType': e.target.value });
  const handlePackTypeChange = (e: any) => setFormData({ ...formData, 'packType': e.target.value });


  return (
    <>
      <div className="row user-input-container">
        <div className="col-11 text-center">
          <label className="user-input p-2">Length:&nbsp;
            <input className="user-input-top" type="number" name="length" min={-1} value={formData.length} onChange={handleDimensionChange} />
          </label>
          <label className="user-input p-2">Width:&nbsp;
            <input className="user-input-top" type="number" name="width" min={-1} value={formData.width} onChange={handleDimensionChange} />
          </label>
          <label className="user-input p-2">Height:&nbsp;
            <input className="user-input-top" type="number" name="height" min={-1} value={formData.height} onChange={handleDimensionChange} />
          </label>
          <label className="user-input p-2">Weight:&nbsp;
            <input className="user-input-top" type="number" name="weight" min={-1} value={formData.weight} onChange={handleDimensionChange} />
          </label>
        </div>
        <br />
        <div className="col-11 text-center">
          <label className="user-input p-2">Dimensional Weight: {props.dimensionalWeight}</label>
          <label className="user-input p-2">Postal Code:&nbsp;
            <input className="user-input-postalcode"  type="text" name="postalCode" value={formData.postalCode} onChange={handlePostalCodeChange} />
          </label>
          <label className="user-input p-2">Service Type:&nbsp;
            <select value={formData.serviceType} onChange={handleServiceTypeChange}>
              <option value={""} />
              {SERVICE_TYPES.map((option, index) => (
                <option key={index} value={option.key}>
                  {option.description}
                </option>
              ))}
            </select>
          </label>
          <label className="user-input p-2">Pack Type:&nbsp;
            <select value={formData.packType} onChange={handlePackTypeChange}>
              {PACK_TYPES.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </label>
        </div>

      </div>
    </>
  );
}