export interface UserInputsInterface {
  length: string,
  width: string,
  height: string,
  weight: string,
  postalCode: string,
  serviceType: string,
  packType?: PackagingType
}

export interface FormattedUserInputsInterface {
  length: number,
  width: number,
  height: number,
  weight: number,
  postalCode: string,
  serviceType?: string,
  packType?: PackagingType
}

export interface DimensionalAttributes {
  Start: number;
  End: number;
  Price_Per_Pound: number;
  PackagingType: PackagingType;
  Sku: string;
  Notes: string;
}

export enum PackagingType {
  Standard = 'Standard',
  Fragile = 'Fragile',
  Basic = 'Basic',
  None = 'None',
}

export interface PackedBox {
  dimensionalWeight: number,
  boxCost: number,
  boxInnerDimensions: {
    length: number,
    width: number,
    height: number
  },
  packingMethod: PackagingType,
  packingPrice: number,
  shippingPrice: number,
  barCode: string
}