import { useState } from 'react';
import Table from 'react-bootstrap/Table'
import { PackedBox } from '../../organisms/interfaces';
import Barcode from 'react-barcode';

import './UpsBoxes.css'

interface IPropsUpsBoxes {
  boxes: PackedBox[]
}

const UpsBoxes = (props: IPropsUpsBoxes) => {

  const [clickedRowKey, setClickedRowKey] = useState<string>('box0')
  const handleRowClick = (e: any, key: string) => setClickedRowKey(key);

  return (
    <div className="mt-5 p-4">
      <Table className="m-auto" responsive="sm" hover>
        <thead >
          <tr>
            <th>Dim Weight</th>
            <th>Packaging Price</th>
            <th>Box Cost</th>
            <th>Shipping Price</th>
            <th>Total Price</th>
            <th>Length</th>
            <th>Width</th>
            <th>Height</th>
            <th>Packing Method</th>
            <th>Barcode</th>
          </tr>
        </thead>
        <tbody>
          {props.boxes && props.boxes.map((box, index) => {
            return <tr key={`box${index}`} className={"ups-boxes-best-borders " + (clickedRowKey === `box${index}` ? "selected" : "")} onClick={e => handleRowClick(e, `box${index}`)}>
              <td>{box.dimensionalWeight}</td>
              <td>{box.packingPrice}</td>
              <td>{box.boxCost}</td>
              <td>{box.shippingPrice}</td>
              <td>{Math.abs(box.packingPrice - box.boxCost + box.shippingPrice).toFixed(2)}</td>
              <td>{box.boxInnerDimensions.length}</td>
              <td>{box.boxInnerDimensions.width}</td>
              <td>{box.boxInnerDimensions.height}</td>
              <td>{box.packingMethod}</td>
              <td>{clickedRowKey === `box${index}` && <Barcode value={box.barCode} height={50} width={1} displayValue={true} textPosition={"top"} marginTop={1} />}</td>
            </tr>
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default UpsBoxes;