import { Auth } from 'aws-amplify';
import axios from 'axios';
import { config } from "../../atoms/WebsiteAuth/config";
import { FormattedUserInputsInterface } from '../../organisms/interfaces';

export async function getBoxes(userInputs: FormattedUserInputsInterface, dimensionalWeight: number) {
    try {
        const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();

        console.log("Calling BestFit api")
        const response = await axios.get(config.apiGateway.apiUrl! + "BestFit", {
            params: {
                length: userInputs.length,
                width: userInputs.width,
                height: userInputs.height,
                weight: userInputs.weight !== undefined ? Math.max(userInputs.weight, dimensionalWeight) : dimensionalWeight,
                postalCode: userInputs.postalCode,
                packType: userInputs.packType,
                serviceType: userInputs.serviceType
            },
            headers: {
                Authorization: idToken,
            },
        });

        console.log("Best Fit Response", response.data)
        return response.data;
    } catch (error) {
        console.error(error);
    }
};