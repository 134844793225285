import React from 'react';
import { FaUser, FaSignOutAlt } from 'react-icons/fa';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import './TopNavBar.css';

interface TopNavbarProps {
  signOut: any
  user: any
}

const TopNavbar: React.FC<TopNavbarProps> = (props: TopNavbarProps) => {

  function buildUserPropertiesString(attributes: any): React.ReactNode {
    return `${attributes['custom:AddressLine']} ${attributes['custom:City']} ${attributes['custom:PostalCode']}, ${attributes['custom:StateCode']}`
  }

  return (
    <Navbar bg="light" expand="lg">
      {/* Your logo or brand name goes here */}
      <Navbar.Brand className="p-1">Box Dimension Calculator</Navbar.Brand>

      {/* Toggler button for small screens */}
      <Navbar.Toggle aria-controls="navbarNav" />

      {/* Navigation items */}
      <Navbar.Collapse id="navbarNav" className="justify-content-end">
        <Nav className="ml-auto">
          {/* User Info dropdown */}
          <NavDropdown title={<span><FaUser />{props.user.attributes.email}</span>} id="userInfoDropdown">
            <NavDropdown.Item href="#">{buildUserPropertiesString(props.user.attributes)}</NavDropdown.Item>
          </NavDropdown>

          {/* Sign Out */}
          <Nav.Link href="#"><FaSignOutAlt /> Sign Out</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default TopNavbar;
